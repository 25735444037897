<template>
  <transition name="fade">
    <div class="spinner-container" v-if="display">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </transition>
</template>
<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        display: (state) => state.loading.loading,
      }),
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
  .spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    z-index: 10;
  }
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 3rem;
  height: 3rem;
  margin: 8px;
  border: 4px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s ease;
    /* transform: translateX(0); */
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    /* transform: translateX(10px); */
  }
</style>
